

























import { Component, Vue, Prop } from 'vue-property-decorator';

import { countBy } from 'lodash';

import Checkbox from '@/components/Input/Checkbox/index.vue';

import { Video, Teacher } from '@/globalInterfaces/Video';
import { FileVideo, FilterTeachersVideo } from '../../interface';

@Component({
  components: {
    Checkbox
  }
})
export default class FilterClass extends Vue {
  @Prop() listVideos!: Array<Video>;

  private classTeacher: Array<Teacher> = [];
  private newListVideos: Array<FileVideo> = [];

  created() {
    this.setVideosToFilter();
  }

  get filterTeachersVideo(): Array<FilterTeachersVideo> {
    return this.$store.getters.filterTeachersVideo;
  }

  setVideosToFilter() {
    this.addHiddenElementInVideos();
    this.saveListModifiedVideosInStore();
    this.getDataFromTeachersFromVideos();
    this.getNumberVideosPerTeacher();
  }

  addHiddenElementInVideos() {
    this.newListVideos = this.listVideos.map((video) => ({ ...video, hidden: false }));
  }

  saveListModifiedVideosInStore() {
    this.$store.commit('setFilterVideoList', this.newListVideos);
  }

  getDataFromTeachersFromVideos() {
    this.classTeacher = this.newListVideos.map((video) => video.teachers[0]);
  }

  getNumberVideosPerTeacher() {
    const numberVideosPerTeacher = countBy(this.classTeacher, 'name');
    const newListTeachers: Array<Record<string, any>> = [];

    Object.keys(numberVideosPerTeacher).forEach((teacher: string) => newListTeachers.push({
      name: teacher,
      quantityVideos: numberVideosPerTeacher[teacher],
      checked: true
    }));

    this.$store.commit('setFilterTeachersVideo', newListTeachers);
  }

  setCheckbox(name: string, checked: boolean, index: any, checkValue: boolean) {
    if (!this.filterTeachersVideo.length) return;

    const selectedTeacher = this.filterTeachersVideo.map((item: FilterTeachersVideo) => {
      const teacher = item;

      if (teacher?.name === name) teacher.checked = checkValue;

      return teacher;
    });

    this.$store.commit('setFilterTeachersVideo', selectedTeacher);
  }
}
